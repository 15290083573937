
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: larger;
}

td, th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}
th {
    background-color: #636262;
    color:white;
    font-weight: bold;
}

/* ts {
    background-color: #739fa7;
    font-weight: bold;


} */

tr:nth-child(even) {
  background-color: #e2e2e2;
}
/* 
th {
width:100%;
text-align: center;
background-color: #dddddd;


} */
