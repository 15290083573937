/* Carousel Container */
.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  
  /* Carousel Track */
  .carousel-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  /* Carousel Item */
  .carousel-item {
    min-width: 100%;
    box-sizing: border-box;
    padding: 15px;
  }
  
  /* Client Logo Container */
  .client-logo-container {
    border: 2px solid #ddd; /* Border color */
    border-radius: 15px; /* Rounded corners */
    padding: 20px; /* Padding inside the border */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff; /* Background color inside the border */
  }
  
  /* Client Logo */
  .client-logo {
    max-width: 100%;
    max-height: 100px; /* Adjust based on your requirement */
  }
  
  /* Navigation Buttons */
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .carousel-button.prev {
    left: 10px;
  }
  
  .carousel-button.next {
    right: 10px;
  }
  
  /* Responsive Settings */
  @media (min-width: 600px) {
    .carousel-item {
      min-width: 50%;
    }
  }
  
  @media (min-width: 1024px) {
    .carousel-item {
      min-width: 33.33%;
    }
  }
  
  @media (min-width: 1440px) {
    .carousel-item {
      min-width: 25%;
    }
  }
  